import React from "react";
import { BrowserRouter } from "react-router-dom";

import WabiProvider from "wabi_ui/core/Provider";

import SessionProvider from "layout/Session";
import TrackingProvider from "layout/TrackingProvider";
import Routes from "./routes";

import "./polyfills";

const theme = { colors: { gray: { lighter: "#EBEAE8", main: "#d0cecb", darker: "#444444" } } };

function App() {
  return (
    <WabiProvider theme={theme} redux>
      <SessionProvider>
        <TrackingProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </TrackingProvider>
      </SessionProvider>
    </WabiProvider>
  );
}

export default App;
