import { get as baseGet, post as basePost, put as basePut, delete as baseDelete } from "wabi_ui/api";

import { API_URL } from "utils/environment";

import { hasAuthToken, getAuthToken } from "utils/auth";

function getAuthConfig() {
  if (hasAuthToken()) {
    return { Authorization: `bearer ${getAuthToken()}` };
  } else {
    return {};
  }
}

export const get = (url, query = {}) => baseGet(`${API_URL}${url}`, query, getAuthConfig());
export const post = (url, body = {}) => basePost(`${API_URL}${url}`, body, getAuthConfig());
export const put = (url, body = {}) => basePut(`${API_URL}${url}`, body, getAuthConfig());
export const remove = (url, query = {}) => baseDelete(`${API_URL}${url}`, query, getAuthConfig());

export const getBuffer = url => fetch(`${API_URL}${url}`, {
  method: "get",
  headers: new Headers({
    ...getAuthConfig(),
    "Content-Type": "application/json",
  }),
})
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = event => {
        try {
          resolve(event.target.result);
        } catch (e) {
          resolve("");
        }
      };
      reader.readAsDataURL(blob);
    } catch (e) {
      resolve("");
    }
  }));
