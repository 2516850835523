/* eslint-disable object-curly-spacing */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-danger */
import React, { Suspense, lazy, useEffect } from "react";
import { withRouter, Switch, Route, useHistory } from "react-router-dom";

import asyncComponent from "wabi_ui/core/AsyncComponent";
import PageLoading from "wabi_ui/core/PageLoading";

import ROUTES from "utils/routes";

import { pageView } from "layout/TrackingProvider";

function retry(fn, retriesLeft = 3, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            window.location.reload();
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

const InstallView = lazy(() => retry(() => import("./Pages/Install" /* webpackChunkName: "install" */)));

const CheckoutView = lazy(() => retry(() => import("./Pages/Checkout" /* webpackChunkName: "checkout" */)));

const NotFoundView = lazy(() => retry(() => import("./Pages/404" /* webpackChunkName: "404" */)));
const LoginView = lazy(() => retry(() => import("./Pages/Login" /* webpackChunkName: "login" */)));
const SignupOldRedirectView = lazy(() => retry(() => import("./Pages/Signup_old" /* webpackChunkName: "signup" */)));
const LostPasswordView = lazy(() => retry(() => import("./Pages/LostPassword" /* webpackChunkName: "lost.password" */)));
const ResetPasswordView = lazy(() => retry(() => import("./Pages/ResetPassword" /* webpackChunkName: "reset.password" */)));

const DashboardHomeView = lazy(() => retry(() => import("./Pages/Dashboard/Home" /* webpackChunkName: "dashboard.home" */)));
const DashboardProfileView = lazy(() => retry(() => import("./Pages/Dashboard/Profile" /* webpackChunkName: "dashboard.profile" */)));
const DashboardPaymentsView = lazy(() => retry(() => import("./Pages/Dashboard/Payments" /* webpackChunkName: "dashboard.payments" */)));
const DashboardDocumentsView = lazy(() => retry(() => import("./Pages/Dashboard/Documents" /* webpackChunkName: "dashboard.documents" */)));
const DashboardSignView = lazy(() => retry(() => import("./Pages/Dashboard/Signs" /* webpackChunkName: "dashboard.sign" */)));
const DashboardFriendsView = lazy(() => retry(() => import("./Pages/Dashboard/Friends" /* webpackChunkName: "dashboard.friends" */)));

const PaymentOkView = lazy(() => retry(() => import("./Pages/Payments/ok" /* webpackChunkName: "payments.view" */)));
const PaymentErrorView = lazy(() => retry(() => import("./Pages/Payments/error" /* webpackChunkName: "payments.error" */)));
const PaymentRedirectView = lazy(() => retry(() => import("./Pages/Payments/redirect" /* webpackChunkName: "payments.redirect" */)));
const PaymentSetupErrorView = lazy(() => retry(() => import("./Pages/Payments/setupError" /* webpackChunkName: "payments.setup" */)));

const Routes = ({ location }) => {
  const history = useHistory();

  useEffect(() => {
    changePageCallback();

    history.listen(changePageCallback);
  }, [history]);

  const changePageCallback = () => {
    pageView();
    window.scrollTo(0, 0);
  };

  return (
    <Suspense fallback={<PageLoading />}>
      <Switch location={location}>
        <Route exact path={ROUTES.root} component={asyncComponent(LoginView)} />

        <Route exact path={ROUTES.install} component={asyncComponent(InstallView)} />

        <Route path={ROUTES.checkout} component={asyncComponent(CheckoutView)} />

        <Route path={ROUTES.login} component={asyncComponent(LoginView)} />
        <Route path={ROUTES.signup} component={asyncComponent(SignupOldRedirectView)} />
        <Route path={ROUTES.lostPassword} component={asyncComponent(LostPasswordView)} />
        <Route path={ROUTES.resetPassword} component={asyncComponent(ResetPasswordView)} />
        <Route path={ROUTES.notFound} component={asyncComponent(NotFoundView)} />

        <Route path={ROUTES.payments.ok} component={asyncComponent(PaymentOkView)} />
        <Route path={ROUTES.payments.error} component={asyncComponent(PaymentErrorView)} />
        <Route path={ROUTES.payments.setupError} component={asyncComponent(PaymentSetupErrorView)} />
        <Route path={ROUTES.payments.redirect} component={asyncComponent(PaymentRedirectView)} />
        <Route path={ROUTES.payments.redirectOld} component={asyncComponent(PaymentRedirectView)} />

        <Route path={ROUTES.dashboard.home} component={asyncComponent(DashboardHomeView)} />
        <Route path={ROUTES.dashboard.profile} component={asyncComponent(DashboardProfileView)} />
        <Route path={ROUTES.dashboard.payments} component={asyncComponent(DashboardPaymentsView)} />
        <Route path={ROUTES.dashboard.documents} component={asyncComponent(DashboardDocumentsView)} />
        <Route path={ROUTES.dashboard.signs} component={asyncComponent(DashboardSignView)} />
        <Route path={ROUTES.dashboard.friends} component={asyncComponent(DashboardFriendsView)} />

        <Route component={asyncComponent(NotFoundView)} />
      </Switch>
    </Suspense>
  );
};

export default withRouter(Routes);
