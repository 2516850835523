export const INIT_SESSION_LAUNCH = "INIT_SESSION_LAUNCH";
export const INIT_SESSION_FINISH = "INIT_SESSION_FINISH";
export const UPDATE_STATUS_SESSION_FINISH = "UPDATE_STATUS_SESSION_FINISH";

export const INIT_CHECKOUT_LAUNCH = "INIT_CHECKOUT_LAUNCH";
export const INIT_CHECKOUT_FINISH = "INIT_CHECKOUT_FINISH";

export const INIT_USER_DASHBOARD_LAUNCH = "INIT_USER_DASHBOARD_LAUNCH";
export const INIT_USER_DASHBOARD_FINISH = "INIT_USER_DASHBOARD_FINISH";
export const UPDATE_SERVICE_USER_DASHBOARD = "UPDATE_SERVICE_USER_DASHBOARD";

export const INIT_PAYMENTS_LAUNCH = "INIT_PAYMENTS_LAUNCH";
export const INIT_PAYMENTS_FINISH = "INIT_PAYMENTS_FINISH";

export const INIT_DOCUMENTS_LAUNCH = "INIT_DOCUMENTS_LAUNCH";
export const INIT_DOCUMENTS_FINISH = "INIT_DOCUMENTS_FINISH";

export const SIGN_DOCUMENTS_LAUNCH = "SIGN_DOCUMENTS_LAUNCH";
export const SIGN_DOCUMENTS_FINISH = "SIGN_DOCUMENTS_FINISH";

export const INIT_FRIENDS_LAUNCH = "INIT_FRIENDS_LAUNCH";
export const INIT_FRIENDS_FINISH = "INIT_FRIENDS_FINISH";
