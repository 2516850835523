import React, { useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";

import FacebookPixel from "react-facebook-pixel";
import Analytics from "react-ga";

import { getStateValue } from "wabi_ui/utils/redux";

import { ENABLE_TRACKING, DEBUG_TRACKING, FACEBOOK_ID, ANALYTICS_ID } from "utils/environment";

export const pageView = () => {
  if (DEBUG_TRACKING) console.log("PAGEVIEW");
  FacebookPixel.pageView();
  Analytics.pageview(window.location.pathname);
};

export const event = ({ name, label = undefined, category = "Event", value = undefined }) => {
  if (DEBUG_TRACKING) console.log("EVENT", name, category, label);
  FacebookPixel.track(name, { value, label, category });
  Analytics.event({ category, action: name, label, value });
};

const TrackingProvider = ({ children, user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const track = ENABLE_TRACKING === true || ENABLE_TRACKING === "true";

  useEffect(() => {
    if (track) {
      if (DEBUG_TRACKING) console.log("INIT TRACKING");

      if (!!FACEBOOK_ID) {
        FacebookPixel.init(
          FACEBOOK_ID,
          { userId: (!!user && user._id) ? user._id : "no-logued" },
          { autoConfig: true, debug: DEBUG_TRACKING },
        );
      }

      if (!!ANALYTICS_ID) {
        Analytics.initialize(
          ANALYTICS_ID,
          { debug: DEBUG_TRACKING, titleCase: false, gaOptions: { userId: !!user && user._id ? user._id : "no-logued" } },
        );
      }
    }

    setIsLoading(false);
  }, [user, track]);

  if (isLoading) return (<Fragment />);

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

const mapStateToProps = ({ sessionState }) => getStateValue({ state: sessionState, values: { user: false } });

export default connect(mapStateToProps)(TrackingProvider);
