import { get, post, getBuffer } from "./utils";

export const getUser = () => get("/private/users/me");
export const changePassword = (currentPass, newPass) => post("/private/users/me/password", { currentPass, newPass });
export const getStatus = () => get("/private/users/me/status");
export const getCheckoutInfo = () => get("/private/users/me/checkout");

export const getServices = () => get("/private/users/me/services");
export const addService = service => post("/private/users/me/services", service);
export const cardReceive = id => post(`/private/users/me/services/${id}/card`);
export const getConsumoPhoneService = id => get(`/private/users/me/services/${id}/consumo`);

export const getPayments = () => get("/private/users/me/payments");
export const getPaymentsSetupSession = () => get("/private/users/me/payments/card/setup");
export const getCheckout = (id, checkout = true) => get(`/private/users/me/payments/${id}/checkout`, { checkout });

export const getDocuments = () => get("/private/users/me/documents");
export const getReadyDocuments = () => get("/private/users/me/documents/ready");
export const getPendingDocuments = () => get("/private/users/me/documents/pending");

export const uploadDocument = (type, base64) => post(`/private/users/me/documents/${type}`, { base64 });
export const getSignedDocument = id => getBuffer(`/private/users/me/documents/${id}`);
export const getDocumentToSign = id => get(`/private/users/me/documents/signs/${id}`);
export const uploadSign = (id, base64) => post(`/private/users/me/documents/signs/${id}`, { base64 });

export const lostPassword = username => post("/users/lost_password", { username });
export const resetPassword = (password, hmac) => post("/users/reset_password", { password, hmac });

export const getFriendsStatus = () => get("/private/users/me/friends/status");
export const getFriends = () => get("/private/users/me/friends");
export const applyFriendCode = code => post("/private/users/me/friends", { code });

export const subscribePush = payload => post("/private/users/me/push_notifications", payload);
