export const isPWA = () => (window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true);

export const isIos = () => {
  const ua = window.navigator.userAgent;
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i);

  return isIPad || isIPhone;
};

export const isSafari = () => {
  const ua = window.navigator.userAgent;
  const webkit = !!ua.match(/WebKit/i);

  return webkit && !ua.match(/CriOS/i);
};

export const existNotification = () => {
  try {
    return typeof Notification !== "undefined";
  } catch (e) {
    return false;
  }
};
