/* eslint-disable react/no-multi-comp */
import React, { Fragment } from "react";
import { connect } from "react-redux";

import ModuleLoader from "wabi_ui/core/ModuleLoader";
import PageLoading from "wabi_ui/core/PageLoading";

import { getStateValue } from "wabi_ui/utils/redux";

import { config } from "./redux";

const SessionProvider = ({ children, isLoading }) => {
  if (isLoading) return (<PageLoading />);

  return (<Fragment>{children}</Fragment>);
};

const mapStateToProps = ({ sessionState }) => getStateValue({ state: sessionState, values: { isLoading: false } });

const Component = connect(mapStateToProps)(SessionProvider);

// eslint-disable-next-line react/jsx-props-no-spreading
export default props => (<ModuleLoader config={config} Component={() => (<Component {...props} />)} />);
