import produce from "immer";

import { launchAction } from "wabi_ui/utils/redux";

import { INIT_SESSION_LAUNCH, INIT_SESSION_FINISH, UPDATE_STATUS_SESSION_FINISH } from "utils/actions";
import { METHODS as PAYMENT_METHODS } from "utils/payments";

import { getUser, getStatus } from "api/user";

const initAction = async dispatch => {
  launchAction(dispatch, INIT_SESSION_LAUNCH, {});

  let [user, status] = await Promise.all([getUser().catch(false), getStatus().catch(false)]).catch(() => [false, false]);

  if (!user || (!!user.err && user.err === 403)) user = false;
  if (!status || (!!status.err && status.err === 403)) status = false;

  launchAction(dispatch, INIT_SESSION_FINISH, { status, user });
};

export const updateStatus = status => dispatch => {
  launchAction(dispatch, UPDATE_STATUS_SESSION_FINISH, { status });
};

const defaultState = {
  isLoading: true,
  user: false,
  isPaymentConfig: false,
  isPaymentCardConfig: false,
};

const reducer = (state, action) => (
  produce(state || defaultState, possibleState => {
    switch (action.type) {
      case INIT_SESSION_LAUNCH:
        possibleState.isLoading = true;

        break;
      case INIT_SESSION_FINISH:
        possibleState.isLoading = false;

        // eslint-disable-next-line no-case-declarations
        const { user, status } = action.payload;

        possibleState.user = user;
        possibleState.status = status;
        possibleState.isPaymentConfig = !!user.paymentMethod && !!PAYMENT_METHODS[user.paymentMethod] && !!status.checkoutSetup;
        possibleState.isPaymentCardConfig = !!user.paymentMethod && PAYMENT_METHODS.CARD.value === user.paymentMethod && !!status.checkoutSetup;

        break;
      case UPDATE_STATUS_SESSION_FINISH:
        possibleState.status = action.payload.status;

        break;
      default:
      // do nothing
    }
  })
);

export const config = {
  id: "sessionModule",
  reducerMap: { sessionState: reducer },
  initialActions: [initAction],
  finalActions: [],
};
