export const STATUS = {
  WAITING: { value: "WATING", label: "Pendiente", color: "warning" },
  ERROR: { value: "ERROR", label: "Pendiente", color: "warning" },
  PAYED: { value: "PAYED", label: "Pagado", color: "success" },
  EXPIRED: { value: "EXPIRED", label: "Pendiente", color: "danger" },
  DOWN: { value: "DOWN", label: "Expirado", color: "danger" },
};

export const TYPES = {
  BILL: { value: "BILL", label: "Factura" },
  FIRST_BILL: { value: "FIRST_BILL", label: "Primera Factura" },
  NEW_NUMBER: { value: "NEW_NUMBER", label: "Número Nuevo" },
  PORTA_PREPAGO: { value: "PORTA_PREPAGO", label: "Portabilidad" },
  WIFI_NO_PERMANENCE: { value: "WIFI_NO_PERMANENCE", label: "Instalación Fibra" },
  OTHER: { value: "OTHER", label: "Pago Especial" },
  SEND_CARD: { value: "SEND_CARD", label: "Envío SIM" },
};

export const METHODS = {
  CARD: { value: "CARD", label: "Tarjeta de crédito o débito" },
  BANK: { value: "BANK", label: "Cuenta bancaria" },
};

export const isValidPaymentMethod = method => !!method && METHODS[method];
