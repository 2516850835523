export default ({
  root: "/",
  install: "/install",
  checkout: "/checkout",
  login: "/login",
  signup: "/signup",
  lostPassword: "/lost-password",
  resetPassword: "/change-password",
  payments: {
    ok: "/payments/ok",
    error: "/payments/error",
    setupError: "/payments/setup/error",
    redirect: "/payments/redirect",
    redirectOld: "/payment/redirect",
  },
  dashboard: {
    home: "/dashboard/home",
    profile: "/dashboard/profile",
    payments: "/dashboard/payments",
    signs: "/dashboard/signs",
    documents: "/dashboard/documents",
    friends: "/dashboard/friends",
  },
  notFound: "/404",
});
